import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Card from "../components/Card"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Form, Button, Container, Row, Col } from '@themesberg/react-bootstrap';
import Grid from '../components/Grid'
import { navigate } from "gatsby"
import { AcquisitionWidget } from '../components/Widgets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faAngleDown, faAngleUp, faChartArea, faChartBar, faChartLine, faFlagUsa, faFolderOpen, faGlobeEurope, faPaperclip, faUserPlus } from '@fortawesome/free-solid-svg-icons'
const { useEffect } = React;
const Profile1 = "https://scontent.fsin14-1.fna.fbcdn.net/v/t39.30808-6/433016539_931385698993795_3635108807127922107_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=127cfc&_nc_ohc=WiyJspSirNMQ7kNvgGsEDM_&_nc_zt=23&_nc_ht=scontent.fsin14-1.fna&_nc_gid=A7LUSFQ1VSbxRvO5ln7Kbk5&oh=00_AYB7PTqUEt-A3MCtcLDCIwg7DbuHdf7z_35dBXYtEk7Wqw&oe=673277C0";
const IndexPage = () => {

    const url = `https://api.whatsapp.com/send?phone=6587413159&text=` + encodeURIComponent(`I want to enquiry on full account`)

    const AddButton = () => {
        return <Button onClick={() => navigate('/register')} variant="primary" size="sm" className="me-2">
            Sign Up For Free
        </Button>
    }
    return <Layout >
        <Container>
            <h2 className="text-center">Claim Your Account</h2>
            <Row>
                <Col>
                    <AcquisitionWidget
                        title="Free Limited Account (Limited View)"
                        description={"Just sign up an account with us and you enjoy the following: "}
                        body={<div className="d-block text-center">
                            <div className="d-flex align-items-center pt-3 me-5">
                                <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
                                    <FontAwesomeIcon icon={faChartBar} />
                                </div>
                                <div className="d-block">
                                    <label className="mb-0">Daily Monitoring for 1 mobile number pattern </label>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pt-3">
                                <div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
                                    <FontAwesomeIcon icon={faChartArea} />
                                </div>
                                <div className="d-block">
                                    <label className="mb-0">Database to search for mobile Number</label>
                                </div>
                            </div>

                        </div>}

                        footer={<AddButton />}
                    />
                </Col>

                <Col>

                    <AcquisitionWidget
                        title="Free Full Account (Full View)"
                        description={"Just sign up circles.life plan account with us and you enjoy the following: "}
                        body={<div className="d-block text-center">
                            <div className="d-flex align-items-center pt-3 me-5">
                                <div className="icon icon-shape icon-sm icon-shape-danger rounded me-3">
                                    <FontAwesomeIcon icon={faChartBar} />
                                </div>
                                <div className="d-block">
                                    <label className="mb-0">Daily Monitoring for 3 mobile number pattern</label>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pt-3">
                                <div className="icon icon-shape icon-sm icon-shape-quaternary rounded me-3">
                                    <FontAwesomeIcon icon={faChartArea} />
                                </div>
                                <div className="d-block">
                                    <label className="mb-0">Database to search for mobile Number</label>
                                </div>
                            </div>
                            <div className="d-flex align-items-center pt-3">

                                <div className="d-block">
                                    <label className="mb-0">Circles.life plan includes:</label>
                                    <p>
                                        100GB Borderless (5G)<br />
                                        * Data shared across 9 Countries
                                        <br />Singapore, Malaysia, Indonesia, Thailand, Taiwan, Hong Kong, Macau, Sri Lanka & Bangladesh
                                        <br />
                                        * Unlimited Local Talktime (Incoming & Outgoing)
                                        <br />
                                        * 25 SMS<br />
                                    </p>
                                </div>
                            </div>
                        </div>}
                        footer={<Button onClick={() => navigate(url)} variant="success" size="sm" className="me-2">
                            whatsapp us to sign up
                        </Button>}
                    /></Col>
            </Row>
        </Container>

    </Layout >
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
